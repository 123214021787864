/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import ImageWithAuth from '../../hooks/useDriverSignature';
import { ReactComponent as ArrowRigthIcon } from '../../icons/arrowcircleright.svg';
import { CREATE_DROPOFF } from '../../services/graphql/dropoffs/dropoffsMutations';
import { dropoffSelectedOptionsAtom } from '../../services/recoil/atoms/dropoffSelectedOptions';
import type { DropdownTruckOptions } from '../../types/vendors';
import VendorOptionsDropdown from '../atoms/Dropdowns/VendorOptionsDropdown';
import VendorDropdown from '../atoms/Dropdowns/VendorsDropdown';
import InputNumber from '../atoms/Inputs/InputNumber';
import InputOptionsCarrousel from '../atoms/Inputs/InputOptionsCarrousel';
import SignatureCanvas from '../atoms/Signature';
import AddDriverModalForm from './AddDriverModalForm';
import AddTruckModalForm from './AddTruckModalForm';

interface UpdateFormData {
  vendor_id: string;
  driver_id: string;
  truck_id: string;
  location_id: string;
  total_gallons?: number;
  total_pounds?: number;
  container_volume?: number;
  container_volume_show?: number;
  miu_percentage: number;
  ffa: number;
  sulfur: number;
  registered_by: string;
  traceability: boolean | 'Yes' | 'No';
  manifest_number: string;
  miu: number;
  net_ikg: number;
  measuring_method: string;
  percentage_fill: number;
}

function VendorDropoffForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useRecoilState(
    dropoffSelectedOptionsAtom,
  );
  const [unit, setUnit] = useState<'Lbs' | 'Gal'>('Gal');

  const [createDropoff] = useMutation(CREATE_DROPOFF);

  const { handleSubmit, watch, control, setValue } = useForm<UpdateFormData>({
    defaultValues: {
      registered_by: selectedOptions.registered_by,
      location_id: selectedOptions.location_id,
      measuring_method: selectedOptions.location?.measuring_method,
    },
  });

  const {
    truck_id: truckId,
    vendor_id: vendorId,
    container_volume: containerVolume,
    total_gallons: totalGallons,
    total_pounds: totalPounds,
    miu_percentage: miuPercentage,
    miu: miuTotal,
  } = watch();

  const [signature, setSignature] = useState<File | null>(null);

  const onSubmit: SubmitHandler<UpdateFormData> = async (data) => {
    createDropoff({
      variables: {
        ...data,
        traceability: data.traceability === 'Yes',
        driver_signature: signature,
      },
      onCompleted: (res) => {
        if (res.createVendorDropoff.data) {
          setSelectedOptions({
            registered_by: '',
            location_id: '',
            location: null,
            registered: null,
          });
          navigate('/dash');
        }
      },
    });
  };

  useEffect(() => {
    if (selectedOptions.location?.measuring_method === 'scale') setUnit('Lbs');
    else if (selectedOptions.location?.measuring_method === 'meter')
      setUnit('Gal');
    setValue('total_gallons', undefined);
    setValue('total_pounds', undefined);
  }, [selectedOptions.location?.measuring_method]);

  useEffect(() => {
    setValue('driver_id', '');
    setValue('truck_id', '');
  }, [vendorId]);

  useEffect(() => {
    if (!truckId) setValue('container_volume', undefined);
    if (!truckId) setValue('container_volume_show', undefined);
  }, [truckId]);

  useEffect(() => {
    if (totalGallons && unit === 'Gal')
      setValue('total_pounds', totalGallons * 7.5);
  }, [totalGallons]);

  useEffect(() => {
    if (totalPounds && unit === 'Lbs')
      setValue('total_gallons', totalPounds / 7.5);
  }, [totalPounds]);

  useEffect(() => {
    if (containerVolume && totalGallons)
      setValue('percentage_fill', (totalGallons / containerVolume) * 100);
  }, [containerVolume, totalGallons]);

  useEffect(() => {
    if (unit === 'Gal' && totalGallons && miuPercentage)
      setValue('miu', totalGallons * 7.5 * (miuPercentage / 100));
    if (unit === 'Lbs' && totalPounds && miuPercentage)
      setValue('miu', totalPounds * 1.21 * (miuPercentage / 100));
  }, [totalGallons, totalPounds, miuPercentage]);

  useEffect(() => {
    if (miuTotal && totalPounds) setValue('net_ikg', totalPounds - miuTotal);
  }, [miuTotal, totalPounds]);

  const onTruckSelect = (truck: DropdownTruckOptions) => {
    const container = Number(truck.capacity);
    if (unit === 'Lbs') setValue('container_volume_show', container * 7.5);
    else setValue('container_volume_show', container);
    setValue('container_volume', container);
  };

  return (
    <form
      className="flex flex-col gap-6 overflow-hidden"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col gap-6">
        <h2 className="text-lg font-semibold text-ReyesBlack ">
          {t('dropoff.vendor-details')}
        </h2>
      </div>
      <div>
        <VendorDropdown
          title={t('dropoff.vendor-name')}
          control={control}
          id="vendor_id"
        />
      </div>
      <div>
        <VendorOptionsDropdown
          id="driver_id"
          title={t('dropoff.driver-name')}
          vendorId={vendorId}
          type="drivers"
          control={control}
          CreateModal={AddDriverModalForm}
          setValue={(val: string) => setValue('driver_id', val)}
        />
      </div>
      <div>
        <VendorOptionsDropdown
          id="truck_id"
          title={t('dropoff.select-truck')}
          vendorId={vendorId}
          type="trucks"
          control={control}
          CreateModal={AddTruckModalForm}
          setValue={(val: string) => setValue('truck_id', val)}
          onOptionSelected={onTruckSelect}
        />
      </div>
      <div className="flex flex-col gap-6">
        <h2 className="text-lg font-semibold text-ReyesBlack ">
          {t('dropoff.entry-data')}
        </h2>
      </div>
      <div>
        <InputNumber
          label={t('dropoff.gross-ikg')}
          control={control}
          defaultValue=""
          inputField={unit === 'Gal' ? 'total_gallons' : 'total_pounds'}
          validations={{
            required: t('global.required'),
          }}
          suffix={` ${unit}`}
          decimalCount={0}
        />
      </div>
      <div>
        <InputNumber
          label={t('dropoff.container-volumne')}
          control={control}
          defaultValue=""
          inputField="container_volume_show"
          validations={{
            required: t('global.required'),
          }}
          suffix={` ${unit}`}
          disabled
          decimalCount={0}
        />
      </div>
      <div>
        <InputNumber
          label={t('dropoff.percentage-fill')}
          control={control}
          defaultValue=""
          inputField="percentage_fill"
          validations={{
            required: t('global.required'),
          }}
          suffix={` %`}
          disabled
          decimalCount={2}
        />
      </div>
      <div>
        <InputNumber
          label={t('dropoff.miu-percentage')}
          control={control}
          defaultValue=""
          inputField="miu_percentage"
          validations={{
            required: t('global.required'),
          }}
          suffix={` %`}
          decimalCount={2}
        />
      </div>
      <div>
        <InputNumber
          label={t('dropoff.total-miu')}
          control={control}
          defaultValue=""
          inputField="miu"
          validations={{
            required: t('global.required'),
          }}
          suffix={` Lbs`}
          decimalCount={2}
        />
      </div>
      <div>
        <InputNumber
          label={t('dropoff.net-ikg')}
          control={control}
          defaultValue=""
          inputField="net_ikg"
          validations={{
            required: t('global.required'),
          }}
          suffix={` Lbs`}
          decimalCount={2}
        />
      </div>
      <div>
        <InputNumber
          label={t('dropoff.ffa')}
          control={control}
          defaultValue=""
          inputField="ffa"
          validations={{
            required: t('global.required'),
          }}
          suffix={` ppm`}
          decimalCount={1}
        />
      </div>
      <div>
        <InputNumber
          label={t('dropoff.sulfur')}
          control={control}
          defaultValue=""
          inputField="sulfur"
          validations={{
            required: t('global.required'),
          }}
          suffix={` ppm`}
          decimalCount={1}
        />
      </div>
      <div>
        <InputOptionsCarrousel
          label={t('dropoff.traceability')}
          control={control}
          options={['Yes', 'No']}
          inputField="traceability"
          validations={{
            required: t('global.required'),
          }}
        />
      </div>
      {/* Signatures */}
      <span>
        <p className="text-sm text-ReyesBlack">
          {t('dropoff.operator-signature')}
        </p>
        <ImageWithAuth signature={selectedOptions.registered?.signature} />
      </span>
      <span>
        <p className="text-sm text-ReyesBlack">
          {t('dropoff.client-signature')}
        </p>
        <div className="flex w-full flex-col justify-center">
          <SignatureCanvas onSignatureSave={setSignature} />
        </div>
        <button
          type="submit"
          id="SaveManifest"
          className="mt-2 inline-flex h-12 w-full items-center justify-start gap-2.5 rounded-xl bg-ReyesGreen px-2.5 py-2 text-center text-lg font-black text-white shadow-custom"
        >
          {t('dropoff.register-dropoff')}
          <ArrowRigthIcon />
        </button>
      </span>
    </form>
  );
}

export default VendorDropoffForm;
