import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { GET_DRIVER } from '../services/graphql/drivers/getDriverInfo';
import { driverStateAtom } from '../services/recoil/atoms/driverState';

export function useUserData(auth0Id: string | undefined) {
  const setDriverState = useSetRecoilState(driverStateAtom);
  const { data, refetch } = useQuery(GET_DRIVER, {
    variables: { auth0Id },
    skip: !auth0Id,
  });

  useEffect(() => {
    if (data && data.getUser && data.getUser.data) {
      const driverData = data.getUser.data;
      setDriverState(driverData);
    }
  }, [auth0Id, data, setDriverState]);

  return { data, refetch };
}
