/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as ElementIcon } from '../../icons/element.svg';
import { ReactComponent as UserIcon } from '../../icons/useroctagon.svg';
import BackButton from '../atoms/BackButton';

interface NavProps {
  label?: string;
}
function Navbar(props: NavProps) {
  const { label } = props;
  const location = useLocation();

  return (
    <div className="fixed left-0 top-0 z-[2] mx-auto h-[70px] w-full backdrop-blur-md">
      <nav className="mx-auto flex h-[70px] w-full max-w-[90vw] flex-row items-center justify-between">
        {location.pathname === '/dash' ||
        location.pathname === '/' ||
        location.pathname === '/profile' ? (
          <Link to="/dash" id="dash">
            <div className="relative h-6 w-6 shrink-0">
              <ElementIcon />
            </div>
          </Link>
        ) : (
          <BackButton />
        )}
        <h1 className="text-xl font-bold text-ReyesBlack">{label}</h1>
        <Link to="/profile" id="profile">
          <div className="relative  h-6 w-6 shrink-0">
            <UserIcon />
          </div>
        </Link>
      </nav>
    </div>
  );
}

export default Navbar;
