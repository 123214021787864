import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ReactComponent as VendorIcon } from '../../../icons/vendor.svg';
import { driverStateAtom } from '../../../services/recoil/atoms/driverState';
import BigButtonIcon from '../../atoms/BigIconButton';
import ButtonCA from '../../atoms/ButtonCA';
import { Loading } from '../../atoms/Loader';
import Title from '../../atoms/Title';
import Layout from '../Layout';

function OperatorDeviceDashboard() {
  const { t } = useTranslation();
  const user = useRecoilValue(driverStateAtom);
  const navigate = useNavigate();

  if (!user) return <Loading size={50} />;

  return (
    <>
      <Layout>
        <div id="dashboardNoRoute">
          <Title
            firstName={user?.name}
            text={t('dashboard.titleText')}
            textSpan={''}
          />
          <div className="mx-auto grid w-full max-w-[900px] grid-cols-2 gap-2 md:grid-cols-3">
            <BigButtonIcon
              icon={<VendorIcon />}
              label={t('dashboard.vendor-dropoff')}
              onClick={() => navigate('/vendor-dropoff-1')}
            />
          </div>
        </div>
        <ButtonCA />
      </Layout>
    </>
  );
}
export default OperatorDeviceDashboard;
