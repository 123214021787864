type ButtonProps = {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  selected?: boolean;
};

const BigButtonIcon = ({ onClick, icon, label, selected }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`mt-2 inline-flex flex-col items-center justify-center gap-2.5 rounded-lg ${
        selected ? 'bg-ReyesGold' : 'bg-ReyesGreen'
      } px-2.5 py-2 shadow-custom`}
    >
      <div>{icon}</div>
      <p className={`text-center text-lg font-black text-white`}>{label}</p>
    </button>
  );
};

export default BigButtonIcon;
