import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { GET_ROUTE_BY_DRIVER } from '../services/graphql/routes/routes-querys'; // Importa tu consulta GraphQL
import { driverStateAtom } from '../services/recoil/atoms/driverState';
import { mutationStateAtom } from '../services/recoil/atoms/mutationState';
import { routeStateAtom } from '../services/recoil/atoms/routeState';
import { getToday } from '../utils/dates';

export function useRouteData(userId: string | undefined) {
  const setRouteState = useSetRecoilState(routeStateAtom);
  const userInfo = useRecoilValue(driverStateAtom);

  const { data, refetch } = useQuery(GET_ROUTE_BY_DRIVER, {
    // Date format: YYYY-MM-DD
    variables: { userId, date: getToday() },
    skip: !userId || userInfo?.role !== 'Driver',
  });

  useEffect(() => {
    if (data && data.getRouteByDriver) {
      const routeInfo = data.getRouteByDriver.data;
      setRouteState(routeInfo);
    }
  }, [data, userId, mutationStateAtom]);

  return { data, mutationStateAtom, refetch };
}
