import { useRecoilValue } from 'recoil';

import type { ROLES } from '../config/permissions';
import { driverStateAtom } from '../services/recoil/atoms/driverState';

export const usePermissions = () => {
  const user = useRecoilValue(driverStateAtom);

  const hasUserAuthority = () => {
    return !!user?.role;
  };

  const checkRole = (role: ROLES) => {
    if (!user?.role) return false;
    return [role.valueOf()].includes(user.role);
  };

  return {
    hasUserAuthority,
    checkRole,
  };
};
