// eslint-disable-next-line import/no-extraneous-dependencies
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useLinkTruck } from '../../hooks/linkTruck';
import NfcIcon from '../../icons/nfc.png';
import { ReactComponent as QrIcon } from '../../icons/scan-barcode.svg';
import QrScanner from '../../services/qr/qrScanner';
import { driverStateAtom } from '../../services/recoil/atoms/driverState';
import { getToday } from '../../utils/dates';
import Button from '../atoms/Button';
import ButtonCA from '../atoms/ButtonCA';
import Title from '../atoms/Title';
import StatusBar from '../molecules/StatusBar';
import Layout from './Layout';

function StartQR() {
  const navigate = useNavigate();
  const driver = useRecoilValue(driverStateAtom);
  const { t } = useTranslation();
  const [showScanner, setShowScanner] = useState(false);
  const { refetch } = useLinkTruck();
  const [qrError, setQrError] = useState(false);

  const handleScan = async (qrInfo: any) => {
    if (qrInfo !== null && driver !== null) {
      const response = await refetch({
        userId: driver.id,
        truckId: qrInfo,
        // Date format: YYYY-MM-DD
        date: getToday(),
      });
      if (response.data.linkTruck) {
        navigate('/updateinfo');
      } else {
        setQrError(true);
      }
    }
  };

  const closeModal = () => {
    setShowScanner(false);
  };

  const openModal = () => {
    setShowScanner(true);
  };

  return (
    <>
      <Layout>
        <div className="">
          <span>
            <Title text={t('startqr.titleText')} />
            <div className="">
              <StatusBar
                text={[
                  t('progressBar.link'),
                  t('progressBar.update'),
                  t('progressBar.choose'),
                ]}
                active={[true, false, false]}
                done={[false, false, false]}
                justifyStart={true}
              />
            </div>
          </span>
          <span>
            <div className="container flex w-72 flex-col items-center justify-center gap-2 text-center text-ReyesBlack">
              <img className="mt-8 h-[250px]" src={NfcIcon} alt="NFC Icon" />
              <div
                onClick={openModal}
                id="startQRButton"
                className="flex flex-col items-center gap-y-2"
              >
                <Button
                  icon={<QrIcon />}
                  label={t('startqr.button1')}
                  color="ReyesGreen"
                  justify="center"
                  width="[60vw]"
                />
                {qrError && (
                  <span className="text-red-500">{t('startqr.qrError')}</span>
                )}
                <div />
              </div>
              <Link to="/startroutemanual" id="startRouteManual">
                <p className=" text-[12px] font-normal underline">
                  {t('startqr.button2')}
                </p>
              </Link>
            </div>
            <ButtonCA />
          </span>
          <Modal
            isOpen={showScanner}
            onRequestClose={closeModal}
            appElement={document.getElementById('root') || undefined}
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
              content: {
                maxWidth: '300px',
                maxHeight: '500px',
                margin: 'auto',
                borderRadius: '20px',
              },
            }}
            contentLabel="Alert"
          >
            <QrScanner
              onScan={handleScan}
              onClose={closeModal}
              modalStatus={showScanner}
            />
          </Modal>
        </div>
      </Layout>
    </>
  );
}

export default StartQR;
