import { gql } from '@apollo/client';

export const CREATE_VENDOR_TRUCK = gql`
  mutation CreateVendorTruck(
    $vendor_id: String!
    $license_plate: String!
    $tk: String!
    $capacity: Float!
  ) {
    createVendorTruck(
      vendor_id: $vendor_id
      license_plate: $license_plate
      tk: $tk
      capacity: $capacity
    ) {
      status
      code
      data
      error
    }
  }
`;
export const CREATE_VENDOR_DRIVER = gql`
  mutation CreateVendorDriver(
    $vendor_id: String!
    $name: String!
    $phone: String!
  ) {
    createVendorDriver(vendor_id: $vendor_id, name: $name, phone: $phone) {
      status
      code
      data
      error
    }
  }
`;

export const CREATE_DROPOFF = gql`
  mutation CreateVendorDropoff(
    $vendor_id: String!
    $driver_id: String!
    $truck_id: String!
    $location_id: String!
    $total_gallons: Float!
    $total_pounds: Float!
    $container_volume: Float!
    $miu_percentage: Float!
    $ffa: Float!
    $sulfur: Float!
    $registered_by: String!
    $traceability: Boolean!
    $miu: Float!
    $net_ikg: Float!
    $measuring_method: String!
    $driver_signature: Upload
  ) {
    createVendorDropoff(
      vendor_id: $vendor_id
      driver_id: $driver_id
      truck_id: $truck_id
      location_id: $location_id
      total_gallons: $total_gallons
      total_pounds: $total_pounds
      container_volume: $container_volume
      miu_percentage: $miu_percentage
      ffa: $ffa
      sulfur: $sulfur
      registered_by: $registered_by
      traceability: $traceability
      miu: $miu
      net_ikg: $net_ikg
      measuring_method: $measuring_method
      driver_signature: $driver_signature
    ) {
      status
      code
      data
      error
    }
  }
`;
