/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../config/constants';
import { formatDateWithTime } from '../../hooks/dateFormatter';
import ImageWithAuth from '../../hooks/useDriverSignature';
import { useRouteData } from '../../hooks/useRouteData';
import { ReactComponent as ArrowRigthIcon } from '../../icons/arrowcircleright.svg';
import { SET_STOP_AS_FINISH } from '../../services/graphql/stops/stopFinished';
import { driverStateAtom } from '../../services/recoil/atoms/driverState';
import { mutationStateAtom } from '../../services/recoil/atoms/mutationState';
import { routeStateAtom } from '../../services/recoil/atoms/routeState';
import { activeStopStateAtom } from '../../services/recoil/atoms/stopState';
import {
  formatNumber,
  formatNumberPercentage,
  inputFormatNumber,
  inputFormatNumberMoney,
} from '../../utils/numberFormatter';
import { Loading } from '../atoms/Loader';
import SignatureCanvas from '../atoms/Signature';
import Title from '../atoms/Title';
import ButtonsManifest from '../molecules/ButtonsManifest';
import ButtonsManifestCarousel from '../molecules/ButtonsManifestCarousel';
import Layout from '../templates/Layout';

interface UpdateFormData {
  container_capacity: string;
  total_gallons: string;
  measuring_method: 'Metered' | 'Weighted' | 'Mathematical' | '';
  percentage_filled: string;
  client_signature: string;
  service: string;
  paymentType: string;
  pricePaid: string;
  paymentStatus: string;
  barrelsPickedUp: string;
  barrelsDropped: string;
  barrelSize: string;
}

function Manifest() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stop = useRecoilValue(activeStopStateAtom);
  const route = useRecoilValue(routeStateAtom);

  if (!route) {
    navigate('/dash');
    return null;
  }
  const currentDate = new Date();
  const formattedDate = formatDateWithTime(currentDate);
  const driver = useRecoilValue(driverStateAtom);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<UpdateFormData>({
    defaultValues: {
      total_gallons: '',
      measuring_method: '',
      percentage_filled: '',
      container_capacity: stop.serviceContainerSize
        ? `${formatNumber(stop.serviceContainerSize)} Gal`
        : '0 Gal',
      pricePaid: '',
      paymentType: '',
      service: '',
      paymentStatus: '',
      barrelsPickedUp: '',
      barrelsDropped: '',
      barrelSize: '',
    },
  });
  const { total_gallons: totalGallons, paymentStatus } = watch();

  const [signature, setSignature] = useState<File | null>(null);
  const [setStopAsFinish] = useMutation(SET_STOP_AS_FINISH);
  const [paymentType, setPaymentType] = useState([]);
  const [paidClient /* , setPaidClient */] = useState(stop.clientPaid);
  const [service, setService] = useState([]);
  const { refetch } = useRouteData(driver?.id);
  const setMutationState = useSetRecoilState(mutationStateAtom);
  const handleMutation = () => {
    setMutationState((prevState) => !prevState);
  };
  const [loading, setLoading] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const [displayDropped, setDisplayDropped] = useState('');
  const [barrelSize, setDisplayBarrelSize] = useState(
    stop.serviceBarrelSize || 0,
  );
  const [displayValueMoney, setDisplayValueMoney] = useState('');

  const onSubmit: SubmitHandler<UpdateFormData> = async (data) => {
    setLoading(true);
    await setStopAsFinish({
      variables: {
        stopId: stop.id,
        total_gallons: parseInt(data.total_gallons, 10),
        measuring_method: data.measuring_method,
        img_client_signature: signature,
        routeId: route.id,
        payment_type: String(paymentType),
        services: JSON.stringify(service),
        pricePaid: Number(data.pricePaid),
        payment_status: data.paymentStatus,
        barrelsPickedUp: Number(data.barrelsPickedUp),
        barrelsDropped: Number(data.barrelsDropped),
        barrelSize: Number(data.barrelSize),
      },
    });
    await refetch();
    handleMutation();
    navigate('/firststop');
    setLoading(false);
  };
  const paymentTypes = [
    'Cash',
    'Contract',
    'C.O.D.',
    'Warranty',
    'Estimate',
    'Charge',
  ];
  const services = [
    'Hood Cleaning',
    'Filters Cleaning Exchange',
    'Equipament Cleaning & Maintenance',
    'Floors & Walls Cleaning',
    'Hood Blower Motor & Bearing Repair',
    'V-belts',
    'Exhaust Cleaning',
    'Other',
  ];

  useEffect(() => {
    if (stop.serviceContainerSize && totalGallons !== '') {
      const newPercentage = Number(totalGallons) / stop.serviceContainerSize;
      setValue('percentage_filled', `${formatNumberPercentage(newPercentage)}`);
    } else {
      setValue('percentage_filled', '0%');
    }
  }, [totalGallons]);

  useEffect(() => {
    setValue('barrelSize', stop.serviceBarrelSize || 0);
  }, [stop]);

  return (
    <Layout>
      {loading ? (
        <Loading size={50} />
      ) : (
        <>
          <Title text={t('manifest.title')} />
          <form
            className="flex flex-col gap-6 overflow-hidden"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-6">
              <h2 className="text-lg font-semibold text-ReyesBlack ">
                {stop.clientName}
              </h2>
              <span>
                <p className="text-sm text-ReyesBlack">{t('manifest.date')}</p>
                <p className="text-sm text-ReyesGrey">{formattedDate}</p>
              </span>
            </div>
            {route.service === FAT_BONES_SERVICE_NAME && (
              <div className="flex flex-col gap-2">
                <span className="">
                  <p className="text-sm text-ReyesBlack">
                    {t('manifest.currentBarrels')}
                  </p>
                  <p className="text-sm text-ReyesGrey">
                    {stop.estimated_barrel}
                  </p>
                </span>
                <span>
                  <p className="text-sm text-ReyesBlack">
                    {t('manifest.barrelsPickedUp')}
                  </p>
                  <Controller
                    name="barrelsPickedUp"
                    control={control}
                    rules={{
                      required: `${t('manifest.error2')}`,
                      pattern: /^[0-9]*$/,
                      maxLength: 10,
                    }}
                    render={({ field }) => (
                      <>
                        <input
                          type="number"
                          id="barrelsPickedUp"
                          {...field}
                          style={{ display: 'none' }}
                        />
                        <input
                          className="w-full border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey focus:outline-none"
                          type="text"
                          id="barrelsPickedUpDisplay"
                          value={displayValue}
                          maxLength={10}
                          onChange={(e) => {
                            const numberValue = Number(
                              e.target.value.replace(/,/g, ''),
                            );
                            if (!Number.isNaN(numberValue)) {
                              field.onChange(numberValue);
                              setDisplayValue(String(numberValue));
                            }
                          }}
                          style={{
                            appearance: 'none',
                            MozAppearance: 'textfield',
                          }}
                        />
                      </>
                    )}
                  />
                  {errors.barrelsPickedUp && (
                    <>
                      <br />
                      <span className="text-red-500">
                        {errors.barrelsPickedUp.message}
                      </span>
                    </>
                  )}
                </span>
                <span>
                  <p className="text-sm text-ReyesBlack">
                    {t('manifest.barrelsDropped')}
                  </p>
                  <Controller
                    name="barrelsDropped"
                    control={control}
                    rules={{
                      required: `${t('manifest.error2')}`,
                      pattern: /^[0-9]*$/,
                      maxLength: 10,
                    }}
                    render={({ field }) => (
                      <>
                        <input
                          type="number"
                          id="barrelsDropped"
                          {...field}
                          style={{ display: 'none' }}
                        />
                        <input
                          className="w-full border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey focus:outline-none"
                          type="text"
                          id="barrelsDroppedDisplay"
                          value={displayDropped}
                          maxLength={10}
                          onChange={(e) => {
                            const numberValue = Number(
                              e.target.value.replace(/,/g, ''),
                            );
                            if (!Number.isNaN(numberValue)) {
                              field.onChange(numberValue);
                              setDisplayDropped(String(numberValue));
                            }
                          }}
                          style={{
                            appearance: 'none',
                            MozAppearance: 'textfield',
                          }}
                        />
                      </>
                    )}
                  />
                  {errors.barrelsDropped && (
                    <>
                      <br />
                      <span className="text-red-500">
                        {errors.barrelsDropped.message}
                      </span>
                    </>
                  )}
                </span>
                <span>
                  <p className="text-sm text-ReyesBlack">
                    {t('manifest.barrelSize')}
                  </p>
                  <Controller
                    name="barrelSize"
                    control={control}
                    rules={{
                      required: `${t('manifest.error2')}`,
                      pattern: /^[0-9]*$/,
                      maxLength: 10,
                    }}
                    render={({ field }) => (
                      <>
                        <input
                          type="number"
                          id="barrelSize"
                          {...field}
                          style={{ display: 'none' }}
                        />
                        <input
                          className="w-full border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey focus:outline-none"
                          type="text"
                          id="barrelSizeDisplay"
                          value={barrelSize}
                          maxLength={10}
                          onChange={(e) => {
                            const numberValue = Number(
                              e.target.value.replace(/,/g, ''),
                            );
                            if (!Number.isNaN(numberValue)) {
                              field.onChange(numberValue);
                              setDisplayBarrelSize(String(numberValue));
                            }
                          }}
                          style={{
                            appearance: 'none',
                            MozAppearance: 'textfield',
                          }}
                        />
                      </>
                    )}
                  />
                  {errors.barrelSize && (
                    <>
                      <br />
                      <span className="text-red-500">
                        {errors.barrelSize.message}
                      </span>
                    </>
                  )}
                </span>
              </div>
            )}

            {(route.service === OIL_DISPOSAL_SERVICE_NAME ||
              route.service === GREASE_TRAP_SERVICE_NAME) && (
              <>
                <div className="flex flex-col gap-6">
                  <span>
                    <p className="text-sm text-ReyesBlack">
                      {t('manifest.ccapacity')}
                    </p>
                    <Controller
                      name="container_capacity"
                      control={control}
                      disabled
                      render={({ field }) => (
                        <input
                          className="w-80 bg-transparent text-sm text-ReyesGrey focus:outline-none"
                          type="text"
                          id="container_capacity"
                          {...field}
                          disabled
                          style={{
                            appearance: 'none',
                            MozAppearance: 'textfield',
                          }}
                        />
                      )}
                    />
                    {errors.container_capacity && (
                      <>
                        <br />
                        <span className="text-red-500">
                          {errors.container_capacity.message}
                        </span>
                      </>
                    )}
                  </span>
                  <span>
                    <p className="text-sm text-ReyesBlack">
                      {t('manifest.tGallons')}
                    </p>
                    <Controller
                      name="total_gallons"
                      control={control}
                      rules={{
                        required: `${t('manifest.error2')}`,
                        pattern: /^[0-9]*$/,
                        maxLength: 8,
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            type="number"
                            id="total_gallons"
                            {...field}
                            style={{ display: 'none' }}
                          />
                          <input
                            className="w-80 border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey ring-ReyesGreen focus:outline-none"
                            type="text"
                            id="total_gallonsDisplay"
                            value={displayValue}
                            maxLength={8}
                            onChange={(e) => {
                              const numberValue = Number(
                                e.target.value.replace(/,/g, ''),
                              );
                              if (!Number.isNaN(numberValue)) {
                                field.onChange(numberValue);
                                setDisplayValue(inputFormatNumber(numberValue));
                              }
                            }}
                            style={{
                              appearance: 'none',
                              MozAppearance: 'textfield',
                            }}
                          />
                        </>
                      )}
                    />
                    {errors.total_gallons && (
                      <span className="ml-2 text-red-500">
                        {errors.total_gallons.message}
                      </span>
                    )}
                  </span>
                  {route.service === OIL_DISPOSAL_SERVICE_NAME &&
                    paidClient && (
                      <div>
                        <span>
                          <p className="text-sm text-ReyesBlack">
                            {t('manifest.amountToPay')}
                          </p>
                          <Controller
                            name="pricePaid"
                            control={control}
                            rules={{
                              required: `${t('manifest.error2')}`,
                              pattern: /^[0-9]*$/,
                              maxLength: 10,
                            }}
                            render={({ field }) => (
                              <>
                                <input
                                  type="number"
                                  id="pricePaid"
                                  {...field}
                                  style={{ display: 'none' }}
                                />
                                <input
                                  className="w-full border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey focus:outline-none"
                                  type="text"
                                  id="pricePaidDisplay"
                                  value={displayValueMoney}
                                  maxLength={10}
                                  onChange={(e) => {
                                    const stringValue = e.target.value.replace(
                                      /[$,]/g,
                                      '',
                                    );
                                    if (!Number.isNaN(Number(stringValue))) {
                                      const numberValue = Number(stringValue);
                                      field.onChange(numberValue);
                                      setDisplayValueMoney(
                                        inputFormatNumberMoney(numberValue),
                                      );
                                    }
                                  }}
                                  style={{
                                    appearance: 'none',
                                    MozAppearance: 'textfield',
                                  }}
                                />
                              </>
                            )}
                          />
                          {errors.pricePaid && (
                            <>
                              <br />
                              <span className="text-red-500">
                                {errors.pricePaid.message}
                              </span>
                            </>
                          )}
                        </span>
                        <div>
                          <p className="mt-2 text-sm text-ReyesBlack">
                            {t('manifest.paymentStatus')}
                          </p>
                          <div className="container flex justify-center">
                            <Controller
                              name="paymentStatus"
                              control={control}
                              rules={{ required: `${t('manifest.error1')}` }}
                              render={({ field }) => (
                                <div className="flex-col">
                                  <div className="text-center">
                                    <ButtonsManifest
                                      field={field}
                                      options={['Yes', 'No']}
                                    ></ButtonsManifest>
                                    {errors.paymentStatus && (
                                      <span className="text-red-500">
                                        {errors.paymentStatus.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                  {route.service === OIL_DISPOSAL_SERVICE_NAME &&
                    paymentStatus === 'Yes' && (
                      <div>
                        <p className="mb-2 text-sm text-ReyesBlack">
                          {t('manifest.paymentT')}
                        </p>
                        <Controller
                          name="paymentType"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `${t('manifest.dropdownError')}`,
                          }} // Add this line
                          render={({ field }) => (
                            <div className="flex-col">
                              <div className="text-center">
                                <ButtonsManifestCarousel
                                  field={field}
                                  options={paymentTypes}
                                  setState={setPaymentType}
                                  state={paymentType}
                                ></ButtonsManifestCarousel>
                                {errors.paymentType && (
                                  <span className="text-red-500">
                                    {errors.paymentType.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    )}
                  <div>
                    <p className="mb-2 text-sm text-ReyesBlack">
                      {t('manifest.measuringMethod')}
                    </p>
                    <div className="container flex justify-center">
                      <Controller
                        name="measuring_method"
                        control={control}
                        rules={{ required: `${t('manifest.error1')}` }}
                        render={({ field }) => (
                          <div className="flex-col">
                            <div className="text-center">
                              <ButtonsManifest
                                field={field}
                                options={[
                                  'Metered',
                                  'Weighted',
                                  'Mathematical',
                                ]}
                              ></ButtonsManifest>
                              {errors.measuring_method && (
                                <span className="text-red-500">
                                  {errors.measuring_method.message}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-80">
                  <p className="text-sm text-ReyesBlack">
                    {t('manifest.percentage')}
                  </p>
                  <div className="flex">
                    <Controller
                      name="percentage_filled"
                      control={control}
                      // rules={{
                      //   required: `${t('manifest.error2')}`,
                      //   pattern: /^[0-9]*$/,
                      //   maxLength: {
                      //     value: 3,
                      //     message: `${t('manifest.error3')}`,
                      //   },
                      //   validate: (value) =>
                      //     parseInt(value, 10) <= 100 ||
                      //     `${t('manifest.error4')}`,
                      // }}
                      disabled
                      render={({ field }) => (
                        <input
                          className="w-full bg-transparent text-sm text-ReyesGrey focus:outline-none"
                          type="text"
                          id="percentage_filled"
                          {...field}
                          disabled
                          style={{
                            appearance: 'none',
                            MozAppearance: 'textfield',
                          }}
                        />
                      )}
                    />
                    {/* <p className="self-center text-sm font-semibold text-ReyesGrey">
                      %
                    </p> */}
                  </div>
                  {errors.percentage_filled && (
                    <span className="text-red-500">
                      {errors.percentage_filled.message}
                    </span>
                  )}
                </div>
              </>
            )}
            {route.service === HOOD_CLEANING_SERVICE_NAME && (
              <>
                <p className="text-sm text-ReyesBlack">
                  {t('manifest.paymentT')}
                </p>
                <Controller
                  name="paymentType"
                  control={control}
                  defaultValue=""
                  rules={{ required: `${t('manifest.dropdownError')}` }} // Add this line
                  render={({ field }) => (
                    <ButtonsManifestCarousel
                      field={field}
                      options={paymentTypes}
                      setState={setPaymentType}
                      state={paymentType}
                    ></ButtonsManifestCarousel>
                  )}
                />
                {errors.paymentType && (
                  <span className="text-red-500">
                    {errors.paymentType.message}
                  </span>
                )}{' '}
                <p className="text-sm text-ReyesBlack">
                  {t('manifest.service')}
                </p>
                <Controller
                  name="service"
                  control={control}
                  defaultValue=""
                  rules={{ required: `${t('manifest.dropdownError')}` }}
                  render={({ field }) => (
                    <ButtonsManifestCarousel
                      field={field}
                      options={services}
                      setState={setService}
                      state={service}
                      multi={true}
                    ></ButtonsManifestCarousel>
                  )}
                />
                {errors.service && (
                  <span className="text-red-500">{errors.service.message}</span>
                )}
                <span>
                  <p className="text-sm text-ReyesBlack">
                    {t('manifest.price')}
                  </p>
                  <Controller
                    name="pricePaid"
                    control={control}
                    rules={{
                      required: `${t('manifest.error2')}`,
                      pattern: /^[0-9]*$/,
                      maxLength: 10,
                    }}
                    render={({ field }) => (
                      <>
                        <input
                          type="number"
                          id="pricePaid"
                          {...field}
                          style={{ display: 'none' }}
                        />
                        <input
                          className="w-full border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey focus:outline-none"
                          type="text"
                          id="pricePaidDisplay"
                          value={displayValueMoney}
                          maxLength={10}
                          onChange={(e) => {
                            const stringValue = e.target.value.replace(
                              /[$,]/g,
                              '',
                            );
                            if (!Number.isNaN(Number(stringValue))) {
                              const numberValue = Number(stringValue);
                              field.onChange(numberValue);
                              setDisplayValueMoney(
                                inputFormatNumberMoney(numberValue),
                              );
                            }
                          }}
                          style={{
                            appearance: 'none',
                            MozAppearance: 'textfield',
                          }}
                        />
                      </>
                    )}
                  />
                  {errors.pricePaid && (
                    <>
                      <br />
                      <span className="text-red-500">
                        {errors.pricePaid.message}
                      </span>
                    </>
                  )}
                </span>
              </>
            )}
            <span>
              <p className="text-sm text-ReyesBlack">
                {t('manifest.driverSignature')}
              </p>
              <ImageWithAuth />
            </span>
            <span>
              <p className="text-sm text-ReyesBlack">
                {t('manifest.clientSignature')}
              </p>
              <div className="flex w-full flex-col justify-center">
                <SignatureCanvas onSignatureSave={setSignature} />
              </div>
              <button
                type="submit"
                id="SaveManifest"
                className="mt-2 inline-flex h-12 w-full items-center justify-start gap-2.5 rounded-xl bg-ReyesGreen px-2.5 py-2 text-center text-lg font-black text-white shadow-custom"
              >
                {t('manifest.saveAndContinue')}
                <ArrowRigthIcon />
              </button>
            </span>
          </form>
        </>
      )}
    </Layout>
  );
}

export default Manifest;
