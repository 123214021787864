// /* eslint-disable unused-imports/no-unused-vars */
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useRouteData } from './hooks/useRouteData';
import { useSettingData } from './hooks/useSettingData';
import { useUserData } from './hooks/useUserData';
import { AppRouting } from './routes/Routing';
import { authStateAtom } from './services/recoil/atoms/authState';
import { driverStateAtom } from './services/recoil/atoms/driverState';
import { tokenState } from './services/recoil/atoms/tokenState';

function App() {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    error,
    user,
    getAccessTokenSilently,
  } = useAuth0();
  const setAuthState = useSetRecoilState(authStateAtom);
  const setTokenState = useSetRecoilState(tokenState);
  const url = window.location.href;

  useEffect(() => {
    (async () => {
      if (!isAuthenticated && !isLoading && !error) {
        loginWithRedirect({
          authorizationParams: {
            redirect_uri: url,
          },
        });
      }
      if (user && user.sub) {
        const authInfo = user.sub;
        const token = await getAccessTokenSilently();
        setAuthState(authInfo);
        setTokenState(token);
      }
    })();
  }, [
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    error,
    setAuthState,
    user,
  ]);

  /**
   * Initialize Datadog RUM
   */
  useEffect(() => {
    if (!datadogRum.getInternalContext()) {
      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
        site: process.env.REACT_APP_DATADOG_SITE || '',
        service: process.env.REACT_APP_DATADOG_SERVICE || '',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'allow',
      });
    }

    datadogRum.startSessionReplayRecording();
  }, []);

  useEffect(() => {
    if (user && datadogRum) {
      datadogRum.setUser({
        id: user.sub,
        email: user.email,
        name: user.name,
      });
    }
  }, [user, datadogRum]);

  useUserData(user?.sub);
  const driverInfo = useRecoilValue(driverStateAtom);
  useRouteData(driverInfo?.id);
  useSettingData();

  return (
    <>
      <AppRouting />
    </>
  );
}

export default App;
