import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { ReactComponent as LockIcon } from '../../../icons/lock-icon.svg';
import { ReactComponent as SignatureIcon } from '../../../icons/signature-icon.svg';
import { driverStateAtom } from '../../../services/recoil/atoms/driverState';
import Button from '../../atoms/Button';
import ButtonCA from '../../atoms/ButtonCA';
import { Loading } from '../../atoms/Loader';
import Title from '../../atoms/Title';
import Layout from '../Layout';

function OperatorDashboard() {
  const { t } = useTranslation();
  const user = useRecoilValue(driverStateAtom);
  const navigate = useNavigate();

  if (!user) return <Loading size={50} />;

  return (
    <>
      <Layout>
        <div id="dashboardNoRoute">
          <Title
            firstName={user?.name}
            text={t('dashboard.titleText')}
            textSpan={''}
          />
          <div className="container flex justify-center gap-x-4">
            <Button
              justify="end"
              id="finishOnRouteButton"
              icon={<LockIcon />}
              label={t('dashboard.buttonChangePin')}
              color="ReyesGreen"
              reverse
              width="[90vw]"
              onClick={() => navigate('/update-pin')}
            />
          </div>
          <div className="container flex justify-center gap-x-4">
            <Button
              justify="end"
              id="finishOnRouteButton"
              icon={<SignatureIcon />}
              label={t('dashboard.buttonSignature')}
              color="white"
              textColor="text-ReyesBlack"
              reverse
              width="[90vw]"
              onClick={() => navigate('/profile')}
            />
          </div>
        </div>
        <ButtonCA />
      </Layout>
    </>
  );
}
export default OperatorDashboard;
