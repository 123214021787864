import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation UpdateUser($key: String!, $value: String!, $userid: String!) {
    updateUser(key: $key, value: $value, userid: $userid) {
      status
      code
      data
      error
    }
  }
`;
