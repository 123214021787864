/* eslint-disable unused-imports/no-unused-vars */
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { ReactComponent as NextIcon } from '../../../icons/arrowcircleright.svg';
import { ReactComponent as LocationIcon } from '../../../icons/location-tick.svg';
import { GET_LOCATIONS } from '../../../services/graphql/dropoffs/dropoffsQuery';
import { dropoffSelectedOptionsAtom } from '../../../services/recoil/atoms/dropoffSelectedOptions';
import type { GetLocationsResponse, Location } from '../../../types/dropoffs';
import BigButtonIcon from '../../atoms/BigIconButton';
import Button from '../../atoms/Button';
import Title from '../../atoms/Title';
import StatusBar from '../../molecules/StatusBar';
import Layout from '../Layout';

function LocationStep() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useQuery<GetLocationsResponse>(GET_LOCATIONS);
  const [selectedOptions, setSelectedOptions] = useRecoilState(
    dropoffSelectedOptionsAtom,
  );

  const onSelect = (location: Location) => {
    setSelectedOptions({
      ...selectedOptions,
      location_id: location.id,
      location,
    });
  };

  return (
    <>
      <Layout>
        <div className="">
          <span>
            <Title text={t('dropoff.title')} />
            <div className="space-y-2">
              <StatusBar
                text={[
                  t('dropoff.step1'),
                  t('dropoff.step2'),
                  t('dropoff.step3'),
                ]}
                active={[true, false, false]}
                done={[false, false, false]}
                justifyStart={true}
              />
            </div>
          </span>
          <div className="mx-auto grid w-full max-w-[900px] grid-cols-2 gap-2 md:grid-cols-3">
            {data?.getLocations.data.map((l) => (
              <BigButtonIcon
                key={l.id}
                icon={<LocationIcon />}
                label={l.name}
                onClick={() => onSelect(l)}
                selected={l.id === selectedOptions.location_id}
              />
            ))}
          </div>
          <Button
            justify="end"
            id="finishOnRouteButton"
            icon={<NextIcon />}
            label={t('dropoff.next')}
            color="ReyesGreen"
            reverse
            width="[90vw]"
            disabled={!selectedOptions.location_id}
            onClick={() => navigate('/vendor-dropoff-2')}
          />
        </div>
      </Layout>
    </>
  );
}

export default LocationStep;
