import React from 'react';

const KeyboardElement = ({
  text,
  onClick,
}: {
  text: string;
  onClick: (key: string) => void;
}) => {
  return (
    <div className="flex items-center justify-center">
      <button
        onClick={() => onClick(text)}
        className="flex h-[90px] w-[90px] items-center justify-center rounded-full bg-ReyesGreenExtraLight text-lg font-bold"
      >
        {text}
      </button>
    </div>
  );
};

const CurrentPin = ({ fill }: { fill: boolean }) => {
  return (
    <div
      className={`h-[16px] w-[16px] rounded-full border border-ReyesGreen ${
        fill ? 'bg-ReyesGreen' : ''
      }`}
    />
  );
};

const TitlesStepUpdatePin = ({
  title1,
  title2,
}: {
  title1: string;
  title2: string;
}) => {
  return (
    <div className="flex flex-col items-center gap-y-1">
      <p className="text-lg font-semibold">{title1}</p>
      <p>{title2}</p>
    </div>
  );
};

const PinKeyboard = ({
  title1,
  title2,
  handleClickKeyboard,
  password,
  Icon,
  error,
  modal,
}: {
  title1?: string;
  title2?: string;
  handleClickKeyboard: (key: string) => void;
  Icon?: React.ReactNode;
  password: string;
  error?: string;
  modal?: boolean;
}) => {
  return (
    <div
      className={`flex ${
        modal ? 'w-full' : 'min-h-[70vh]'
      } flex-col  justify-between gap-y-10`}
    >
      <div className="flex flex-col items-center gap-y-6">
        {title1 && title2 && (
          <TitlesStepUpdatePin title1={title1} title2={title2} />
        )}
        {Icon && <>{Icon}</>}
        <div className="flex flex-row gap-x-2">
          <CurrentPin fill={password.length >= 1} />
          <CurrentPin fill={password.length >= 2} />
          <CurrentPin fill={password.length >= 3} />
          <CurrentPin fill={password.length === 4} />
        </div>
        <div className="h-[24px] origin-top animate-swing text-red-600">
          {error}
        </div>
      </div>
      <div className="flex w-full  flex-col items-center gap-y-2">
        <div className="grid w-full max-w-[300px] grid-cols-3 gap-y-2">
          <KeyboardElement text="1" onClick={handleClickKeyboard} />
          <KeyboardElement text="2" onClick={handleClickKeyboard} />
          <KeyboardElement text="3" onClick={handleClickKeyboard} />
          <KeyboardElement text="4" onClick={handleClickKeyboard} />
          <KeyboardElement text="5" onClick={handleClickKeyboard} />
          <KeyboardElement text="6" onClick={handleClickKeyboard} />
          <KeyboardElement text="7" onClick={handleClickKeyboard} />
          <KeyboardElement text="8" onClick={handleClickKeyboard} />
          <KeyboardElement text="9" onClick={handleClickKeyboard} />
        </div>
        <KeyboardElement text="0" onClick={handleClickKeyboard} />
      </div>
    </div>
  );
};

export default PinKeyboard;
