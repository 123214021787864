import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

interface ProfileInputProps {
  control: any;
  inputField: string;
  defaultValue: string | undefined;
  label: string;
  type?: string;
  validations?: any;
  prefix?: string;
  suffix?: string;
  disabled?: boolean;
  decimalCount?: number;
}

// Wrap NumericFormat with forwardRef
const NumericFormatWrapper = forwardRef((props: any, ref) => (
  <NumericFormat {...props} getInputRef={ref} />
));

function InputNumber({
  control,
  defaultValue,
  label,
  inputField,
  validations,
  prefix,
  suffix,
  disabled,
  decimalCount,
}: ProfileInputProps) {
  return (
    <div className="flex h-auto  w-full flex-col items-start justify-center gap-y-2 self-stretch rounded">
      <div className="flex flex-col items-start justify-center self-stretch">
        <label className={'text-sm font-normal text-ReyesBlack'}>{label}</label>
      </div>
      <div className="w-full">
        <Controller
          name={inputField}
          control={control}
          defaultValue={defaultValue}
          rules={{ ...validations }}
          render={({ field: { onChange, value, ...rest } }) => (
            <NumericFormatWrapper
              {...rest}
              value={value === undefined || value === null ? '' : value}
              thousandSeparator={true}
              thousandsGroupStyle="thousand"
              decimalScale={decimalCount !== undefined ? decimalCount : 2}
              fixedDecimalScale
              prefix={prefix}
              suffix={suffix}
              onChange={(val: any) =>
                onChange(
                  Number(
                    val.target.value
                      .replace(/["$",",","(",")"]/gi, '')
                      .replace(prefix, '')
                      .replace(suffix, ''),
                  ) || null,
                )
              }
              onBlur={() => {}}
              onBlurCapture={() => {}}
              onChangeCapture={() => {}}
              onPointerLeave={() => {}}
              placeholder={label}
              disabled={disabled}
              className={
                'w-full border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey focus:outline-none'
              }
            />
          )}
        />
      </div>
    </div>
  );
}

export default InputNumber;
