import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import { ReactComponent as AddIcon } from '../../../icons/add.svg';
import { ReactComponent as CapacityIcon } from '../../../icons/capacity.svg';
import { ReactComponent as LicenseIcon } from '../../../icons/hashtag.svg';
import {
  GET_VENDOR_DRIVERS,
  GET_VENDOR_TRUCKS,
} from '../../../services/graphql/dropoffs/dropoffsQuery';
import type {
  DropdownTruckOptions,
  GetDropoffOptionsResponse,
} from '../../../types/vendors';
import { formatNumber } from '../../../utils/numberFormatter';

// Componente de opción personalizada
const CustomOption = (props: any) => {
  const { data, innerRef, innerProps, isSelected } = props;
  const isTruck = data.capacity || data.license_plate;

  // Condicional para aplicar estilos diferentes si es la opción "Add Driver"
  if (data.value === 'add-driver') {
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{ padding: '5px 10px 5px 10px', cursor: 'pointer' }}
        className="flex items-center justify-center gap-x-2 rounded-b bg-ReyesGreenLight  text-white"
      >
        <AddIcon />
        {data.label}
      </div>
    );
  }

  // Opciones normales
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ padding: '5px 10px 5px 10px', cursor: 'pointer' }}
      className={`${
        isSelected
          ? 'bg-ReyesGoldLight'
          : 'bg-white hover:bg-ReyesGreenExtraLight'
      }`}
    >
      {isTruck ? (
        <div className="flex flex-row items-center gap-x-1">
          <LicenseIcon />
          <span>{data.license_plate}</span>
          <span>-</span>
          <LicenseIcon />
          <span>{data.tk}</span>
          <span>-</span>
          <CapacityIcon />
          <span>{formatNumber(data.capacity)}</span>
        </div>
      ) : (
        <>{data.label}</>
      )}
    </div>
  );
};

const VendorOptionsDropdown = ({
  vendorId,
  type,
  title,
  control,
  id,
  CreateModal,
  setValue,
  onOptionSelected,
}: {
  vendorId: string;
  type: 'trucks' | 'drivers';
  title: string;
  control: any;
  id: string;
  CreateModal: any;
  setValue: (val: string) => void;
  onOptionSelected?: (val: DropdownTruckOptions) => void;
}) => {
  const [options, setOptions] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const { refetch, loading } = useQuery<GetDropoffOptionsResponse>(
    type === 'drivers' ? GET_VENDOR_DRIVERS : GET_VENDOR_TRUCKS,
    {
      variables: {
        vendorId,
      },
      skip: !vendorId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (res) => {
        if (type === 'drivers') {
          setOptions(res.getDropoffOptions.data.driverOptions);
        } else {
          setOptions(res.getDropoffOptions.data.truckOptions);
        }
      },
    },
  );

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: '0px !important',
      boxShadow: 'none !important',
      padding: '0px',
      outline: 'none',
      '&:hover': {
        boxShadow: 'none !important',
        border: 'none !important',
      },
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: '0px !important',
      borderRadius: '4px',
      border: 'none',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '0px',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: '0px !important',
    }),
  };

  useEffect(() => {
    if (!loading && vendorId) refetch({ vendorId });
  }, [vendorId]);

  const onNewOption = (newId: string) => {
    refetch({ vendorId });
    setValue(newId);
    setOpenModal(false);
  };

  return (
    <div className="border-b-2 border-ReyesGreyLight text-ReyesGrey">
      <span className="">{title}</span>
      <Controller
        name={id}
        control={control}
        disabled
        render={({ field }) => (
          <Select
            options={[
              ...options,
              {
                value: 'add-driver',
                label:
                  type === 'drivers'
                    ? t('dropoff.add-driver')
                    : t('dropoff.add-truck'),
              },
            ]}
            {...field}
            onChange={(val) => {
              if (val.value === 'add-driver') {
                // Lógica para agregar un nuevo driver
                setOpenModal(true);
              } else {
                field.onChange(val.value);
                if (onOptionSelected)
                  onOptionSelected(options.find((o) => o.value === val.value));
              }
            }}
            value={options.find((o) => o.value === field.value) || null}
            styles={customStyles}
            placeholder={t('dropoff.type-to-search')}
            isLoading={loading}
            components={{
              Option: CustomOption,
            }}
          />
        )}
      />
      <CreateModal
        isOpen={openModal}
        setIsOpen={setOpenModal}
        vendorId={vendorId}
        onCreated={onNewOption}
      />
    </div>
  );
};

export default VendorOptionsDropdown;
