import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  FAT_BONES_SERVICE_NAME,
  GREASE_TRAP_SERVICE_NAME,
  HOOD_CLEANING_SERVICE_NAME,
  OIL_DISPOSAL_SERVICE_NAME,
} from '../../../config/constants';
import { ReactComponent as ArrowIcon } from '../../../icons/arrowcircleright.svg';
import { ReactComponent as Bone } from '../../../icons/bone.svg';
// import ComingSoon from '../../icons/comingsoon.png';
import { ReactComponent as Flag } from '../../../icons/flag.svg';
import { ReactComponent as GreaseTrap } from '../../../icons/grease-trap.svg';
import { ReactComponent as HoodCleaning } from '../../../icons/hood-cleaning.svg';
import { ReactComponent as OilIcon } from '../../../icons/Oil.svg';
import { ReactComponent as ReceiptIcon } from '../../../icons/receipt2.svg';
import { ReactComponent as NoRouteIcon } from '../../../icons/routing.svg';
import { driverStateAtom } from '../../../services/recoil/atoms/driverState';
import { routeStateAtom } from '../../../services/recoil/atoms/routeState';
import Button from '../../atoms/Button';
import ButtonCA from '../../atoms/ButtonCA';
import Card from '../../atoms/Card';
import { Loading } from '../../atoms/Loader';
import LongCard from '../../atoms/LongCard';
import Map from '../../atoms/Mapbox';
import Title from '../../atoms/Title';
import Popup from '../../molecules/Popup';
import Layout from '../Layout';

function DriverDashboard() {
  const { t } = useTranslation();
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}/${
    currentDate.getUTCMonth() + 1
  }/${currentDate.getFullYear()}`;
  const driver = useRecoilValue(driverStateAtom);
  const route = useRecoilValue(routeStateAtom);
  const navigate = useNavigate();

  const [missingSignature, setMissingSignature] = useState(false);

  const hasSignature = driver?.signature !== null;
  const pendingStops = route?.stops.filter(
    (stop: { status: string }) => stop.status === 'Pending',
  );
  const numPendingStops = pendingStops?.length;

  const getIconService = () => {
    switch (route.service) {
      case HOOD_CLEANING_SERVICE_NAME:
        return <HoodCleaning />;
      case GREASE_TRAP_SERVICE_NAME:
        return <GreaseTrap />;
      case OIL_DISPOSAL_SERVICE_NAME:
        return <OilIcon />;
      case FAT_BONES_SERVICE_NAME:
        return <Bone />;
      default:
        return <OilIcon />;
    }
  };
  if (!driver) return <Loading size={50} />;
  if (!route)
    return (
      <>
        <Layout>
          <div id="dashboardNoRoute">
            <Title
              firstName={driver?.name}
              text=""
              textSpan={t('dashboard.noplanned')}
            />
            <div className="container flex justify-center  gap-x-4">
              <NoRouteIcon />
            </div>
          </div>
          <div
            className="
           flex flex-col space-y-2"
          >
            <div className="flex w-full flex-col justify-center pt-5">
              <p className="text-center text-base text-ReyesBlack">
                {t('dashboard.pMoreFeatures')}
              </p>
              {/* <img src={ComingSoon} alt="coming soon image" /> */}
            </div>
          </div>

          <ButtonCA />
        </Layout>
      </>
    );
  const galLeft =
    Number(route?.estimated_gallons || 0) -
    Number(route?.picked_up_gallons || 0);
  const barrelLeft =
    Number(route?.estimated_barrel || 0) - Number(route?.picked_up_barrel || 0);

  return (
    <>
      <Layout>
        {route.status === 'On Going' ? (
          <div id="dashboardOnRoute">
            <Title
              firstName={driver?.name}
              text=""
              textSpan={t('dashboard.onroute')}
            />
          </div>
        ) : (
          <div id="dashboardRoute">
            <Title
              firstName={driver?.name}
              text={t('dashboard.titleText')}
              textSpan={t('dashboard.titleTextSpan')}
            />
          </div>
        )}

        <div className="space-y-2 ">
          <h2 className="text-lg  font-semibold text-ReyesBlack ">
            {route?.route_name}
          </h2>
          <p className="text-base  font-semibold  text-ReyesBlack">
            {formattedDate}
          </p>
        </div>
        <div className="my-6 flex-col justify-center space-y-8">
          <Map />
          <span className="flex w-full justify-between">
            {route.status === 'On Going' ? (
              <div className="inline-flex h-[100px] w-[90vw] items-center justify-center gap-[15px]">
                <LongCard
                  content={`${numPendingStops} / ${route?.stop_count}`}
                  label={t('dashboard.card1')}
                  hoodCleaning={route.service === HOOD_CLEANING_SERVICE_NAME}
                />
                {route.service === FAT_BONES_SERVICE_NAME ? (
                  // Render a different card for FAT_BONES_SERVICE_NAME
                  <Card
                    // Add the specific content and label for this card
                    content={`${barrelLeft.toLocaleString('en-US') || '0'} / ${
                      route?.estimated_barrel?.toLocaleString('en-US') || '0'
                    }`}
                    label={t('dashboard.longCardBarrel')}
                  />
                ) : (
                  (route.service !== HOOD_CLEANING_SERVICE_NAME && (
                    <Card
                      content={`${galLeft.toLocaleString('en-US') || '0'} / ${
                        route?.estimated_gallons?.toLocaleString('en-US') || '0'
                      } Gal`}
                      label={t('dashboard.longCard')}
                    />
                  )) || (
                    <div>
                      <div></div>
                      <Card
                        content={getIconService()}
                        label={t('dashboard.card2')}
                      />
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="grid w-full grid-cols-3 gap-4">
                <Card
                  content={route?.stop_count}
                  label={t('dashboard.card1')}
                />
                <Card content={getIconService()} label={t('dashboard.card2')} />
                {hasSignature && (
                  <Link to="/start" id="StartRouteButton">
                    <Card
                      content={<ArrowIcon />}
                      label={t('dashboard.card3')}
                      bgColor="bg-ReyesGreen"
                      textColor="text-white"
                    />
                  </Link>
                )}
                {!hasSignature && (
                  <button onClick={() => setMissingSignature(true)}>
                    <Card
                      content={<ArrowIcon />}
                      label={t('dashboard.card3')}
                      bgColor="bg-ReyesGreen"
                      textColor="text-white"
                    />
                  </button>
                )}
              </div>
            )}
          </span>
          {route.status === 'On Going' && (
            <span className="container mx-auto flex flex-col items-center gap-x-4">
              {hasSignature && (
                <Link to="/firststop">
                  <Button
                    justify="start"
                    id="continueRouteButton"
                    icon={<ArrowIcon />}
                    label={t('dashboard.buttonOnGoing1')}
                    color="ReyesGold"
                    width="[90vw]"
                  />
                </Link>
              )}
              {!hasSignature && (
                <Button
                  justify="start"
                  onClick={() => setMissingSignature(true)}
                  id="continueRouteButton"
                  icon={<ArrowIcon />}
                  label={t('dashboard.buttonOnGoing1')}
                  color="ReyesGold"
                  width="[90vw]"
                />
              )}
              <Link to="/receipt">
                <Button
                  justify="start"
                  id="receiptButton"
                  icon={<ReceiptIcon />}
                  label={t('dashboard.buttonOnGoing2')}
                  color="ReyesGreenLight"
                  width="[90vw]"
                />
              </Link>
              <Link to="/finishroute">
                <Button
                  justify="start"
                  id="finishOnRouteButton"
                  icon={<Flag />}
                  label={t('dashboard.buttonOnGoing3')}
                  color="ReyesGreen"
                  width="[90vw]"
                />
              </Link>
            </span>
          )}
          <div
            className="mx-auto
           flex flex-col space-y-2"
          >
            <p className="text-center text-base text-ReyesBlack">
              {t('dashboard.pMoreFeatures')}
            </p>
            {/* <div className="flex w-full justify-center">
              <img
                src={ComingSoon}
                alt="coming soon image"
                className="w-full max-w-[500px]"
              />
            </div> */}
          </div>
        </div>
        <Modal
          isOpen={missingSignature}
          onRequestClose={() => setMissingSignature(false)}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
            content: {
              maxWidth: '310px',
              maxHeight: '290px',
              margin: 'auto',
              borderRadius: '20px',
            },
          }}
          contentLabel="Alert"
        >
          <Popup
            closeModal={() => setMissingSignature(false)}
            openModal={() => setMissingSignature(true)}
            iconColor={'#D30000'}
            buttonFunction={() => navigate('/profile')}
            idConfirm="logoutConfirm"
            idCancel="logoutCancel"
            buttonLabel={t('dashboard.missingSignatureButton')}
            alert={t('dashboard.missingSignatureTitle')}
            message={t('dashboard.missingSignatureSubTitle')}
          />
        </Modal>
        <ButtonCA />
      </Layout>
    </>
  );
}
export default DriverDashboard;
