import { Controller } from 'react-hook-form';

interface ProfileInputProps {
  control: any;
  inputField: string;
  defaultValue: string | undefined;
  label: string;
  type?: string;
  validations?: any;
  disabled?: boolean;
}

function Input({
  control,
  defaultValue,
  label,
  inputField,
  type,
  validations,
  disabled,
}: ProfileInputProps) {
  return (
    <div className="flex h-auto  w-full flex-col items-start justify-center gap-y-2 self-stretch rounded">
      <div className="flex flex-col items-start justify-center self-stretch">
        <label className={'text-sm font-normal text-ReyesBlack'}>{label}</label>
      </div>
      <div className="w-full">
        <Controller
          name={inputField}
          control={control}
          defaultValue={defaultValue}
          rules={{ ...validations }}
          render={({ field }) => (
            <input
              {...field}
              className={
                'w-full border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey focus:outline-none'
              }
              type={type}
              placeholder={label}
              disabled={disabled}
            />
          )}
        />
      </div>
    </div>
  );
}

export default Input;
