import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import { GET_VENDORS } from '../../../services/graphql/dropoffs/dropoffsQuery';
import type { GetVendorsResponse } from '../../../types/vendors';

// Componente de opción personalizada
const CustomOption = (props: any) => {
  const { data, innerRef, innerProps, isSelected } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{ padding: '5px 10px 5px 10px', cursor: 'pointer' }}
      className={`${
        isSelected
          ? 'bg-ReyesGoldLight'
          : 'bg-white hover:bg-ReyesGreenExtraLight'
      }`}
    >
      {data.label}
    </div>
  );
};

const VendorDropdown = ({
  title,
  control,
  id,
}: {
  title: string;
  control: any;
  id: string;
}) => {
  const [options, setOptions] = useState<any[]>([]);
  const { loading } = useQuery<GetVendorsResponse>(GET_VENDORS, {
    onCompleted: (res) => {
      setOptions(
        res.getVendors.data.map((v) => ({
          label: v.name,
          value: v.id,
        })),
      );
    },
  });

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: '0px !important',
      boxShadow: 'none !important',
      padding: '0px',
      outline: 'none',
      '&:hover': {
        boxShadow: 'none !important',
        border: 'none !important',
      },
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: '0px !important',
      borderRadius: '4px',
      border: 'none',
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '0px',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: '0px !important',
    }),
  };

  return (
    <div className="border-b-2 border-ReyesGreyLight !text-sm text-ReyesGrey">
      <span className="">{title}</span>
      <Controller
        name={id}
        control={control}
        disabled
        render={({ field }) => (
          <>
            <Select
              {...field}
              onChange={(val) => field.onChange(val.value)}
              value={options.find((o) => o.value === field.value) || null}
              options={[...options]}
              styles={customStyles}
              placeholder={t('dropoff.type-to-search')}
              isLoading={loading}
              components={{
                Option: CustomOption,
              }}
            />
          </>
        )}
      />
    </div>
  );
};

export default VendorDropdown;
