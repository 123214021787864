/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */

type StepState = {
  justifyStart?: boolean;
  justifyCenter?: boolean;
  done: boolean[];
  text: string[];
  active: boolean[];
};
type StepProps = {
  text: string;
  active?: boolean;
  done?: boolean;
  number: string;
};
function StatusBar({
  justifyStart,
  justifyCenter,
  done,
  text,
  active,
}: StepState) {
  const containerClass = `inline-flex h-8 w-96 mr-3 overflow-x items-start gap-5 ${
    justifyStart
      ? 'justify-start'
      : justifyCenter
      ? 'justify-center'
      : 'justify-end'
  }`;
  return (
    <div className={containerClass}>
      {text.map((te, i) => (
        <Step
          key={te + i}
          text={te}
          number={(i + 1).toString()}
          active={active[i]!}
          done={done[i]}
        />
      ))}
    </div>
  );
}

function Step({ text, active, done, number }: StepProps) {
  const textClass = active ? 'text-black' : 'text-gray-500';
  let backgroundClass = 'bg-gray-300';

  if (done) {
    backgroundClass = 'bg-ReyesGold';
  } else if (active) {
    backgroundClass = 'bg-ReyesGreen';
  }
  return (
    <div
      className={`flex items-center justify-start gap-1.5 whitespace-nowrap ${textClass}`}
    >
      <div className="relative h-6 w-6 text-center text-white">
        <div
          className={`absolute left-0 top-0 h-6 w-6 ${backgroundClass} rounded-full`}
        >
          {number}
        </div>
      </div>
      <div className={`text-base font-medium`}>{text}</div>
    </div>
  );
}

export default StatusBar;
