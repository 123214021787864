import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query GetLocations {
    getLocations(limit: 50) {
      status
      code
      error
      count
      data {
        id
        name
        address
        measuring_method
        createdAt
      }
    }
  }
`;

export const GET_VENDORS = gql`
  query GetVendors {
    getVendors(limit: 50) {
      status
      code
      error
      count
      data {
        id
        name
      }
    }
  }
`;

export const GET_VENDOR_DRIVERS = gql`
  query GetDropoffOptions($vendorId: String!) {
    getDropoffOptions(vendorId: $vendorId) {
      status
      code
      data {
        driverOptions {
          label
          value
        }
      }
      error
    }
  }
`;

export const GET_VENDOR_TRUCKS = gql`
  query GetDropoffOptions($vendorId: String!) {
    getDropoffOptions(vendorId: $vendorId) {
      status
      code
      data {
        truckOptions {
          label
          value
          license_plate
          tk
          capacity
        }
      }
      error
    }
  }
`;
