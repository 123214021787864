/* eslint-disable unused-imports/no-unused-vars */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useLinkTruckManual } from '../../hooks/linkTruckManual';
import { ReactComponent as TruckIcon } from '../../icons/truckwhite.svg';
import { driverStateAtom } from '../../services/recoil/atoms/driverState';
import { getToday } from '../../utils/dates';
import ButtonCA from '../atoms/ButtonCA';
import Title from '../atoms/Title';
import Popup from '../molecules/Popup';
import StatusBar from '../molecules/StatusBar';
import Layout from './Layout';

function StartRouteManual() {
  const { t } = useTranslation();

  const driver = useRecoilValue(driverStateAtom);
  const navigate = useNavigate();

  if (!driver) {
    navigate('/dash');
    return null;
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const iconColor = '#C5950B';
  const openModal = () => {
    setModalIsOpen(true);
  };
  const [inputValue, setInputValue] = useState('');
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const { refetch } = useLinkTruckManual();
  const handleLinkClick = async () => {
    try {
      const response = await refetch({
        userId: driver?.id,
        truckId: inputValue,
        // Date format: YYYY-MM-DD
        date: getToday(),
      });
      if (response.data.linkTruck) {
        navigate('/updateinfo');
      } else {
        openModal();
      }
    } catch (error) {
      openModal();
    }
  };
  Modal.setAppElement('#root');
  return (
    <>
      <Layout>
        <div className="">
          <span>
            <Title text={t('startmanual.titleText')} />
            <div className="space-y-2">
              <StatusBar
                text={[
                  t('progressBar.link'),
                  t('progressBar.update'),
                  t('progressBar.choose'),
                ]}
                active={[true, false, false]}
                done={[false, false, false]}
                justifyStart={true}
              />
            </div>
          </span>
          <span>
            <div className="container mt-12 flex flex-col items-center justify-center gap-6 text-center text-ReyesBlack">
              <div className="container">
                <p className="inline-flex h-14 w-full flex-col items-start justify-center rounded text-xs font-normal text-ReyesBlack">
                  {t('startmanual.label')}
                </p>
                <div className=" justify-center gap-0.5 self-stretch">
                  <input
                    type="text"
                    className="w-80 border-b-2 border-ReyesGreyLight bg-transparent text-sm text-ReyesGrey focus:outline-none"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    pattern="[A-Za-z0-9 ]*"
                    id="licensePlateInput"
                  />
                </div>
              </div>
              <Link to="/startqr" id="tryAgainQr">
                <p className=" text-[12px] font-normal underline">
                  {t('startmanual.button1')}
                </p>
              </Link>
            </div>
            <div className="container my-9 flex justify-center">
              <button
                onClick={handleLinkClick}
                id="ButtonLinkTruck"
                className="mt-2 inline-flex h-12 w-[340px] items-center justify-start gap-2.5 rounded bg-ReyesGold px-2.5 py-2 shadow-custom"
              >
                <div className="ml-2 text-center text-lg font-black text-white">
                  {t('startmanual.button2')}
                </div>
                <TruckIcon />
              </button>
            </div>
            <ButtonCA />
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
                content: {
                  maxWidth: '310px',
                  maxHeight: '279px',
                  margin: 'auto',
                  borderRadius: '20px',
                },
              }}
              contentLabel="Alert"
            >
              <Popup
                closeModal={closeModal}
                openModal={openModal}
                iconColor={iconColor}
                buttonFunction={closeModal}
                buttonLabel={t('startmanual.modalbutton')}
                alert={t('startmanual.modalert')}
                message={t('startmanual.modamssg')}
              />
            </Modal>
          </span>
        </div>
      </Layout>
    </>
  );
}

export default StartRouteManual;
