import { Controller } from 'react-hook-form';

import ButtonsManifest from '../../molecules/ButtonsManifest';

interface ProfileInputProps {
  control: any;
  inputField: string;
  label: string;
  validations?: any;
  disabled?: boolean;
  options: string[];
}

function InputOptionsCarrousel({
  control,
  label,
  inputField,
  validations,
  disabled,
  options,
}: ProfileInputProps) {
  return (
    <div>
      <p className="mt-2 text-sm text-ReyesBlack">{label}</p>
      <div className="container flex justify-center">
        <Controller
          name={inputField}
          control={control}
          rules={{ ...validations }}
          disabled={disabled}
          render={({ field }) => (
            <div className="flex-col">
              <div className="text-center">
                <ButtonsManifest
                  field={field}
                  options={options}
                ></ButtonsManifest>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default InputOptionsCarrousel;
