/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable tailwindcss/no-custom-classname */
import React from 'react';

type ButtonProps = {
  icon: React.ReactNode;
  label: string;
  color?: string;
  justify?: string;
  id?: string;
  width: string;
  onClick?: () => void;
  reverse?: boolean;
  textColor?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
};

function FormButton({
  icon,
  label,
  color,
  justify,
  id,
  width,
  onClick,
  reverse,
  textColor,
  disabled,
  type,
}: ButtonProps) {
  return (
    <button
      id={id}
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={`bg-${color} inline-flex ${
        reverse ? 'flex-row-reverse' : 'flex-row'
      } w-${width} items-center justify-${justify} gap-2.5 rounded-lg px-2.5 py-2 border border-ReyesGreen disabled:opacity-80`}
    >
      <p className={`text-center text-sm ${textColor || 'text-white'}`}>
        {label}
      </p>
      {icon}
    </button>
  );
}

export default FormButton;
