import { useEffect, useRef, useState } from 'react';

import { ReactComponent as EraserIcon } from '../../icons/eraser.svg';

interface SignatureCanvasProps {
  onSignatureSave: (signature: File | null) => void;
}

function SignatureCanvas({ onSignatureSave }: SignatureCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Establece el tamaño del área de dibujo para que coincida con el tamaño del elemento
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.lineWidth = 2;
    ctx.strokeStyle = 'black';

    const handleStartDrawing = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      setIsDrawing(true);
      let x;
      let y;

      if (e instanceof MouseEvent) {
        x = e.clientX;
        y = e.clientY;
      } else if (e.touches && e.touches[0]) {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      } else {
        x = 0;
        y = 0;
      }
      ctx.beginPath();
      ctx.moveTo(
        x - canvas.getBoundingClientRect().left,
        y - canvas.getBoundingClientRect().top,
      );
    };

    const handleDrawing = (e: MouseEvent | TouchEvent) => {
      if (!isDrawing) return;
      let x;
      let y;

      if (e instanceof MouseEvent) {
        x = e.clientX;
        y = e.clientY;
      } else if (e.touches && e.touches[0]) {
        x = e.touches[0].clientX;
        y = e.touches[0].clientY;
      } else {
        x = 0;
        y = 0;
      }
      ctx.lineTo(
        x - canvas.getBoundingClientRect().left,
        y - canvas.getBoundingClientRect().top,
      );
      ctx.stroke();
    };

    const handleStopDrawing = () => {
      setIsDrawing(false);
      canvas.toBlob((blob) => {
        if (blob) {
          const signatureFile = new File([blob], 'signature.png', {
            type: 'image/png',
          });
          onSignatureSave(signatureFile);
        } else {
          onSignatureSave(null);
        }
      }, 'image/png');
    };

    canvas.addEventListener('mousedown', handleStartDrawing);
    canvas.addEventListener('touchstart', handleStartDrawing, {
      passive: false,
    });
    canvas.addEventListener('mousemove', handleDrawing);
    canvas.addEventListener('touchmove', handleDrawing, { passive: false });
    canvas.addEventListener('mouseup', handleStopDrawing);
    canvas.addEventListener('touchend', handleStopDrawing);
    canvas.addEventListener('mouseleave', handleStopDrawing);

    // eslint-disable-next-line consistent-return
    return () => {
      canvas.removeEventListener('mousedown', handleStartDrawing);
      canvas.removeEventListener('touchstart', handleStartDrawing);
      canvas.removeEventListener('mousemove', handleDrawing);
      canvas.removeEventListener('touchmove', handleDrawing);
      canvas.removeEventListener('mouseup', handleStopDrawing);
      canvas.removeEventListener('touchend', handleStopDrawing);
      canvas.removeEventListener('mouseleave', handleStopDrawing);
    };
  }, [isDrawing, onSignatureSave]);

  const handleClearCanvas = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    onSignatureSave(null);
  };

  return (
    <div className="relative w-full">
      <canvas
        className="my-8 flex-1 justify-center border-4"
        ref={canvasRef}
        width="100%"
        height="100%"
        id="signatureCanvas"
        style={{ width: '100%', height: '90vw' }}
      />
      <div className="flex flex-row gap-2">
        <button
          type="button"
          className=" absolute right-2 top-8 mt-2 inline-flex items-center justify-start gap-2.5 rounded-[5px] !bg-ReyesGold px-2.5 py-2 text-center text-sm font-semibold text-white"
          onClick={handleClearCanvas}
        >
          <EraserIcon />
        </button>
      </div>
    </div>
  );
}

export default SignatureCanvas;
