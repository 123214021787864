import { atom } from 'recoil';

import type { Location } from '../../../types/dropoffs';
import type { User } from '../../../types/users';

type Atom = {
  location: Location | null;
  registered: User | null;
  location_id: string;
  registered_by: string;
};

export const dropoffSelectedOptionsAtom = atom<Atom>({
  key: 'dropoffSelectedOptions',
  default: {
    registered_by: '',
    location_id: '',
    location: null,
    registered: null,
  },
});
