import { t } from 'i18next';

import Title from '../atoms/Title';
import UpdateTruckInfoForm from '../forms/UpdateTruckInfoForm';
import StatusBar from '../molecules/StatusBar';
import Layout from './Layout';

function UpdateInfo() {
  return (
    <Layout>
      <div className="container">
        <span>
          <Title text={'Starting Route'} />
          <div className="space-y-2">
            <StatusBar
              text={[
                t('progressBar.link'),
                t('progressBar.update'),
                t('progressBar.choose'),
              ]}
              active={[false, true, false]}
              done={[true, false, false]}
              justifyCenter={true}
            />
          </div>
        </span>

        <div className="container mb-16">
          <UpdateTruckInfoForm />
        </div>
      </div>
    </Layout>
  );
}

export default UpdateInfo;
